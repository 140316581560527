/*----------------------
    Error Styles  
------------------------*/
.axil-error-not-found{
    position: relative;
    z-index: 1;
}

.axil-error-not-found {
    position: relative;
    &.fullscreen {

        @media #{$lg-layout} {
            min-height: auto;
            width: 100%;
            padding: 150px 0;
        }

        @media #{$md-layout} {
            min-height: auto;
            width: 100%;
            padding: 150px 0;
        }

        @media #{$sm-layout} {
            min-height: auto;
            width: 100%;
            padding: 150px 0;
        }

    }
    .axil-error {
        @media #{$lg-layout} {
            padding-top: 70px;
        }
        @media #{$md-layout} {
            padding-top: 70px;
        }
        @media #{$sm-layout} {
            padding-top: 70px;
        }
    }
    .shape-group {
        .shape {
            position: absolute;
            z-index: 2;
            &.shape-01 {
                bottom: 19%;
                left: 37%;
            }
            &.shape-02 {
                bottom: 100px;
                right: 11%;
            }
            &.shape-03 {
                bottom: auto;
                left: 0;
                top: 0;
                z-index: -1;
                @media #{$laptop-device} {
                    width: 100%;
                }
                @media #{$lg-layout} {
                    width: 100%;
                }
                @media #{$md-layout} {
                    width: 100%;
                }
                @media #{$sm-layout} {
                    width: 100%;
                }
                img{
                    @media #{$laptop-device} {
                        width: 100%;
                    }
                    @media #{$lg-layout} {
                        width: 100%;
                    }
                    @media #{$md-layout} {
                        width: 100%;
                    }
                    @media #{$sm-layout} {
                        width: 100%;
                    }
                   
                }
            }
            &.shape-04 {
                bottom: auto;
                left: 0;
                @media #{$laptop-device} {
                    bottom: 0;
                    left: 0;
                    max-width: 27%;
                }
                @media #{$lg-layout} {
                    bottom: 0;
                    left: 0;
                    max-width: 27%;
                }
                @media #{$md-layout} {
                    bottom: 0;
                    left: 0;
                    max-width: 27%;
                }
                @media #{$sm-layout} {
                    display: none;
                }
                img{
                    @media #{$laptop-device} {
                        width: 100%;
                    } 
                    @media #{$lg-layout} {
                        width: 100%;
                    } 
                    @media #{$md-layout} {
                        width: 100%;
                    } 
                }
            }
        }
    }
}
.axil-error {
    position: relative;
    z-index: 2;
    .text-image{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50% , -50%);
        z-index: -1;
        @media #{$lg-layout} {
            width: 415px;
        }
        @media #{$md-layout} {
            width: 415px;
        }
        @media #{$sm-layout} {
            width: auto;
            max-width: 217px;
        }
    }
}


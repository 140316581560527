/*-------------------------
    Axil Contact Styles 
--------------------------*/
.axil-address-list {
    .axil-address {
        padding: 0;
        z-index: 2;
        max-width: inherit;
        &::before ,
        &::after {
            display: none;
        }
        h6 {
            &.title {
                font-size: 22px;
                font-weight: 700;
                line-height: 26px;
                margin-bottom: 9px;
            }
        }
        p {
            color: #757589; 
            font-size: 18px;
            line-height: 28px;
            a {
                color: #757589; 
                font-weight: 400;
                @extend %transition;
            }
            &:hover {
                a{
                    color: $primary-color;
                }
            }
        }
        & + .axil-address {
            margin-top: 30px;
        }
    } 
}



/*=============================
    Team Details Styles  
===============================*/

.team-details-inner {
    .inner {
        h1 {
            &.title {
                margin-bottom: 20px;
                @media #{$laptop-device} {
                    font-size: 46px;
                }
                @media #{$lg-layout} {
                    font-size: 42px;
                }
                @media #{$md-layout} {
                    font-size: 40px;
                }
                @media #{$sm-layout} {
                    margin-bottom: 5px;
                    font-size: 32px;
                }
            }
        }
        span {
            &.subtitle{
                display: block;
                color: $primary-color;
                border-bottom: 3px solid currentColor;
                font-weight: 700;
                font-size: 22px;
                line-height: 26px;
                padding-bottom: 20px;
                margin-bottom: 20px;
                @media #{$sm-layout} {
                    padding-bottom: 7px;
                    margin-bottom: 17px;
                }
            }
        }
        ul {
            &.social-share {
                li{
                    a{
                        color: #999AB8;
                    }
                }
            }
        }
        p {
            margin-top: 40px;
            @media #{$sm-layout} {
                margin-top: 20px;
            }
        }
    }
}

.axil-team-details-wrapper {
    padding-top: 250px;
    padding-bottom: 190px;
    @media #{$sm-layout} {
        padding-top: 100px;
        padding-bottom: 60px;
    }
    @media #{$md-layout} {
        padding-top: 100px;
        padding-bottom: 60px;
    }
    .thumbnail {
        position: relative;
        z-index: 2;
        margin-left: -60px;
        @media #{$lg-layout} {
            margin-left: 0;
            padding-left: 20px;
        }
        @media #{$md-layout} {
            margin-left: 0;
        }
        @media #{$sm-layout} {
            margin-left: 0;
        }
        &::before {
            -webkit-transform: perspective(500px) rotateY(-5deg);
            transform: perspective(500px) rotateY(0deg);
            position: absolute;
            width: calc(100% + -3px);
            height: calc(100% + 4px);
            top: calc(25px + -9px);
            content: "";
            border: 2px solid #000345;
            z-index: -1;
            right: calc(25px - -4px);
            @media #{$lg-layout} {
                right: calc(25px - 18px);
            }
            @media #{$sm-layout} {
                display: none;
            }
            @media #{$md-layout} {
                display: none;
            }
        }
        .inner {
            img {

            }
        }
    }
}
























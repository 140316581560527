/* ========= Forms Styles ========= */

.border-radius {
    border-radius: $radius;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
textarea {
    font-size: 18px;
    font-weight: 400;
    height: auto;
    line-height: 28px;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    outline: none;
    border: 0 none;
    border-bottom: 2px solid #C7C7D5;
    /* -- Placeholder -- */
    &::placeholder {
        color: $body-color;
        /* Firefox */
        opacity: 1;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $body-color;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $body-color;
    }

    &.p-holder__active {
        border-color: $primary-color;
        
        /* -- Placeholder -- */
        &::placeholder {
            color: $primary-color;
            /* Firefox */
            opacity: 1;
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $primary-color;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $primary-color;
        }
    }

    &.p-holder__error {
        border-color: #f4282d;

        /* -- Placeholder -- */
        &::placeholder {
            color: #f4282d;
            /* Firefox */
            opacity: 1;
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #f4282d;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #f4282d;
        }

        &:focus {
            border-color: #f4282d;
        }
    }

    &:focus {
        border-color: $primary-color;
    }
}

.input-active {
    @extend .p-holder__active;

    input {
        @extend .p-holder__active;
    }
}

.input-error {
    @extend .p-holder__error;

    input {
        @extend .p-holder__error;
    }
}

// Custom Checkbox and radio button 
input[type="checkbox"],
input[type="radio"] {
    opacity: 0;
    position: absolute;

    ~label {
        position: relative;
        font-size: 15px;
        line-height: 26px;
        color: $body-color;
        font-weight: 400;
        padding-left: 20px;

        &::before {
            content: " ";
            position: absolute;
            top: 6px;
            left: 0;
            width: 14px;
            height: 14px;
            background-color: #fff;
            border: 2px solid rgba(24, 152, 165, 0.2);
            border-radius: 2px;
            transition: all .3s;
        }

        &::after {
            content: " ";
            position: absolute;
            top: 9px;
            left: 2px;
            width: 10px;
            height: 5px;
            background-color: transparent;
            border-bottom: 2px solid #fff;
            border-left: 2px solid #fff;
            border-radius: 2px;
            transform: rotate(-45deg);
            opacity: 0;
            transition: all .3s;
        }
    }

    &:checked {
        ~label {
            &::before {
                background-color: $primary-color;
            }

            &::after {
                opacity: 1;
            }
        }
    }
}

input[type="radio"] {
    ~label {
        &::before {
            border-radius: 50%;
        }

        &::after {
            width: 8px;
            height: 8px;
            left: 3px;
            background: #fff;
            border-radius: 50%;
        }
    }
}













/*------------------------------
    Privacy policy Styles  
--------------------------------*/
.privacy-policy-area {
    a {
        color: $primary-color;
        text-decoration: none;
        transition: all 0.5s;
        display: inline-block;
        position: relative;
        &::after{
            position: absolute;
            bottom: 0;
            width: 0;
            height: 1px;
            content: "";
            @extend %transition;
            left: 0;
            background: $primary-color;
        }
        &:hover {
            &::after {
                width: 100%;
            }
        }
    }
    ul {
        padding-left: 18px;
        list-style: disc;
        ul {
            margin-bottom: 0;
        }
        li {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 18px;
            line-height: 28px;
        }
    }
    h3 {
        margin-bottom: 20px;
    }
    .section-title {
        .title {
            display: inline-block;
            padding-bottom: 16px;
            border-bottom: 3px solid $primary-color;
            margin-bottom: 60px;
            @media #{$sm-layout} {
                margin-bottom: 30px;
            }
            @media #{$md-layout} {
                margin-bottom: 30px;
            }
        }
    }
}
